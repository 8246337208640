import { Layout } from 'components/Layout';
import { graphql } from 'gatsby';
import { Box } from 'src/components/Box';
import { Button } from 'src/components/Button';
import { CheckListItem } from 'src/components/CheckListItem';
import { LandingFeatureGrid } from 'src/components/Landing/LandingFeatureGrid';
import { MediaSection } from 'src/components/MediaSection';
import { MethodsLandingHero } from 'src/components/MethodsLandingHero';
import { PrincipleBenefits } from 'src/components/Principle/Benefits';

export default function DiscordManage({ data }) {
    return (
        <Layout
            pageTitleFull="Dedicated community management for your Discord server"
            siteDescription="Our community management team will help you moderate and manage the day to day of your community and be partners in developing a long term strategy for your community goals."
            contactForm
        >
            <MethodsLandingHero
                title="Dedicated community management for your Discord server"
                image={data.manageHero.childImageSharp.fluid}
                subtitle="Our community manager will help build a healthy and supportive community in Discord while handling the day-to-day communication so you can focus on engaging how you'd like."
            />

            <LandingFeatureGrid
                title="We support your community by building a strategy to retain and engage users"
                items={[
                    {
                        heading: 'host Discord events',
                        content:
                            'Increase participation with text and voice channel events like quiz nights, tournaments, giveaways, and collaborations with others.',
                    },
                    {
                        heading: 'user retention and onboarding',
                        content:
                            'Welcoming onboarding process for new members to get chatting right from the start with personalized welcome screen and dedicated intro channel.',
                    },
                    {
                        heading: 'control moderation and spam',
                        content:
                            'As community moderators ourselves, we develop community rules and enforce them by mitigating spam and de-escalating conflicts.',
                    },
                    {
                        heading: 'introducing new features',
                        content:
                            'Along with open communication and sharing feature progress, we collect community surveys for feedback.',
                    },
                    {
                        heading: 'create positive community culture',
                        content:
                            'Leading by example, we encourage members to follow the rules and etiquette of the server.',
                    },
                    {
                        heading: 'build growth with server insights',
                        content:
                            'After reaching a member milestone, we monitor key metrics to inform community strategy decisions.',
                    },
                ]}
                mb="0"
            />

            <MediaSection
                id="stream"
                flipped
                image={data.manageExperts.childImageSharp.fluid}
                title="Comprehensive, personalized Discord growth strategy with experts"
                backgroundColor="secondaryBackground"
                alt=""
            >
                <Box pt="spacing">
                    {[
                        {
                            title:
                                'Host events, giveaways, tournaments, and routine discussion',
                        },
                        {
                            title: 'Schedule and monitor tasks and bug reports',
                        },
                        {
                            title:
                                'Routine meetings with your staff on progress',
                        },
                        {
                            title:
                                'Ensure members feel seen, heard, and recognized as valued voices in the community',
                        },
                    ].map(highlight => (
                        <CheckListItem
                            key={highlight.title}
                            title={highlight.title}
                        />
                    ))}
                </Box>
            </MediaSection>

            <PrincipleBenefits
                id="benefits"
                mt={0}
                title="Benefits of a hands-on community manager"
                ctaHref="/contact?source=discord-community-management"
                ctaLabel="Contact us"
                benefits={[
                    'increase engagement and brand ambassador support',
                    'develop a long-term Discord growth strategy alongside you',
                    'mitigate toxicity and resolve community conflicts',
                ]}
            />

            <MediaSection
                id="manage"
                bg="secondaryBackground"
                image={data.manageAbstract.childImageSharp.fluid}
                title="Custom bot features driven by our community"
            >
                <Box as="p" lineHeight="1.5">
                    We act as a partner throughout the strategy and bot
                    development process as every community and Discord server is
                    unique.
                </Box>
                <Button
                    mt="12"
                    maxWidth="180px"
                    href="/landing/discord-custom-bot"
                >
                    Bot strategy & development
                </Button>
            </MediaSection>

            <LandingFeatureGrid
                center
                offset={false}
                flexDirection="column"
                title="Related articles"
                sectionHeaderProps={{
                    direction: 'row',
                }}
                customButton={{
                    text: 'See More',
                    href: 'https://adjourn.audent.io/',
                }}
                featureGridProps={{
                    mx: 0,
                    px: 0,
                    columns: [1, 2, null, 4],
                    spacing: {
                        _: 'spacing-lg',
                    },
                }}
                showContact={false}
                items={[
                    {
                        image: data.culture.childImageSharp.fluid,
                        heading:
                            'Helping with community culture on Slack and Discord',
                        content:
                            "We've strived to be thought leaders in the community space, product designers bringing complex but useful products to the XenForo market, and UI/UX designers for what a forum interface...",
                        href:
                            'https://adjourn.audent.io/helping-with-community-culture-on-slack-and-discord-5fa6941f69e9/',
                    },
                    {
                        image: data.guide.childImageSharp.fluid,
                        heading: 'How to build a community on Discord',
                        content:
                            "We're continuing our community guide series to take another look at building a community in Discord. With all of the new features they've added this year and new takes on community strategy, we'll look at how to grow monetization, engagement, and more in your server.",
                        href:
                            'https://adjourn.audent.io/how-to-build-a-community-on-discord/',
                    },
                    {
                        image: data.growing.childImageSharp.fluid,
                        heading:
                            'Growing a community with gamification and incentivization',
                        content:
                            'Online communities, specifically forums, are struggling to keep up with the modern user experience that large social media platforms...',
                        href:
                            'https://adjourn.audent.io/building-an-active-community-through-incentivization-and-gamification-eb0551232b22/',
                    },
                    {
                        image: data.toxicity.childImageSharp.fluid,
                        heading: 'Moderation & Toxicity Prevention Techniques',
                        content:
                            'To maintain a healthy and thriving community, we share the tools to implement from the beginning to help moderate content and prevent toxicity from spreading...',
                        href:
                            'https://adjourn.audent.io/moderation-toxicity-prevention-techniques-3fe9baba3e65/',
                    },
                ]}
            />
        </Layout>
    );
}

export const query = graphql`
    query DiscordManageQuery {
        manageHero: file(
            relativePath: { eq: "images/discord/manage-hero.png" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        manageAbstract: file(
            relativePath: { eq: "images/discord/manage-abstract.png" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        manageExperts: file(
            relativePath: { eq: "images/discord/manage-experts.png" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        culture: file(relativePath: { eq: "images/discord/culture.png" }) {
            publicURL
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        growing: file(relativePath: { eq: "images/discord/growing.png" }) {
            publicURL
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        guide: file(relativePath: { eq: "images/discord/guide.jpg" }) {
            publicURL
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        toxicity: file(relativePath: { eq: "images/discord/toxicity.jpg" }) {
            publicURL
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`;
